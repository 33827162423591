.modelSelectItemWrapper {
  padding: var(--bdo-scale-sm) var(--bdo-scale-md);
  display: flex;
  gap: var(--bdo-scale-2xs);
  cursor: pointer;
  background-color: white;
}
.modelItemBorderStyles {
  border: 1px solid var(--bdo-semi-transparent-lighter);
}
.modelSelectItemWrapper:hover {
  background-color: var(--bdo-semi-transparent-lighter);
}
.modelSelectItemWrapperSelected {
  background-color: var(--bdo-semi-transparent-lightest);
}
.modelSelectItemInfoSectionWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--bdo-scale-2xs);
  flex: 1;
}
.checkIconStyles {
  color: var(--bdo-primary) !important;
  align-items: baseline;
  height: var(--bdo-xxl);
  width: var(--bdo-xxl);
  font-size: var(--bdo-xxl);
  margin-top: -7px;
}

.modelSelectItemWrapper .modelNameHeadingStyles {
  color: var(--bdo-charcoal);
  margin: 0;
  height: var(--bdo-xxxl);
  display: flex;
  align-items: center;
  font-weight: 400 !important;
  font-size: var(--bdo-scale-lg);
}

.modelSelectItemWrapper .modelDescriptionStyles {
  margin: 0;
  color: var(--sub-text);
}
