.audioVisualizerWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  width: 100%;
}
.audoControllerIconStyles {
  padding: var(--bdo-scale-sm);
  color: var(--sub-text);
}
.audoControllerCrossIconStyles {
  color: var(--bdo-primary) !important;
}
