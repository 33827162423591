.BDOButton {
  transition: none !important;
}
.hasMinWidth {
  min-width: 110px;
}
.BDOButton:disabled {
  opacity: 40%;
  cursor: not-allowed !important;
}

.BDOButton:hover,
.BDOButton:active {
  border: 1px solid var(--bdo-charcoal-light) !important;
  color: var(--bdo-charcoal) !important;
}
.bdoTransparentButton {
  display: flex;
  align-items: center;
  background-color: transparent !important;
  border: none;
  padding: var(--scale-2xs, 3.5px) var(--scale-xs, 7px);
  height: auto !important;
  color: var(--bdo-charcoal) !important;
  margin: 0 !important;
}
.bdoTransparentButton:hover {
  background-color: var(--bdo-shade) !important;
  border: none !important;
}
.bdoTransparentButton:disabled {
  cursor: not-allowed !important;
}
.bdoRedOutlined,
.bdoRedOutlined:hover {
  border: 1px solid var(--bdo-primary) !important;
  color: var(--bdo-primary) !important;
}
.iconButton {
  border: none !important;
  color: var(--sub-text);
  transition: none !important;
  line-height: 0;
}
.iconButton:hover {
  background-color: var(--bdo-charcoal-lightest);
  color: var(--sub-text);
  border: none !important;
}
.linkButton {
  color: var(--bdo-link) !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0;
  border-bottom: 1px solid transparent !important;
  height: auto !important;
  word-break: break-all;
}
.linkButton span {
  word-break: break-all !important;
  white-space: normal !important;
  text-align: justify;
}
.linkButton:hover {
  color: var(--bdo-link) !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  /* padding-bottom: var(--bdo-scale-xxxs) !important; */
  border-bottom: 1px solid var(--bdo-link) !important;
}

.textIconButton {
  display: flex;
  align-items: center;
  padding: 0px var(--bdo-scale-sm) !important;
}

.bdoRed {
  background-color: var(--bdo-primary) !important;
  color: var(--bdo-white) !important;
}
.bdoRed:focus,
.bdoRed:focus-visible,
.bdoRed:focus-within {
  outline-color: var(--bdo-info-lighter) !important;
  outline-style: solid !important;
  outline-width: 2px !important;
  border-radius: 0 !important;
}

.bdoRed:hover {
  background-color: var(--bdo-primary-dark) !important;
  color: var(--bdo-white) !important;
}
.defaultButton {
  color: var(--bdo-charcoal) !important;
  background-color: var(--bdo-semi-transparent-lightest, red) !important;
}
