.gptModelPopeOverStyles [class*="ant-popover-inner"] {
  padding: 0px;
}
.gptModelPopeOverStyles [class*="ant-popover-inner-content"] {
  padding: var(--bdo-scale-md);
  display: flex;
  flex-direction: column;
}
.thunderIconStyles {
  margin-top: 1.5px !important;
}
.gptModelPopeOverStyles .infoLinkStyles {
  width: max-content;
}
.activeButtonStyles {
  background-color: var(--bdo-shade) !important;
}
.modelsListingWrapper {
  max-height: 250px;
  overflow-y: auto;
}

.gptModelContentWrapper {
  max-width: 340px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--bdo-scale-sm);
}
.gptModelContentWrapper .gptModelPoperoverLabel {
  font-size: var(--bdo-scale-sm);
  color: var(--sub-text);
  margin: 0;
}
.contentWrapper .textStyles {
  font-size: var(--bdo-scale-md);
  font-weight: var(--bdo-text-base);
  color: var(--bdo-charcoal);
  margin: 0;
  margin-bottom: 8px;
}
.contentWrapper [class*="ant-divider"] {
  margin: 0;
  margin-bottom: 8px;
}
