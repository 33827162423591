.ellipsisFileName {
  white-space: nowrap;
  display: inline-block;
  max-width: 140px;
  font-size: var(--bdo-scale-sm);
  font-weight: var(--bdo-text-base);
  color: var(--bdo-secondary);
}
.tagStyles {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tagWrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 600px;
  overflow: auto;
  gap: var(--bdo-scale-lg);
}
.tagWrapper::-webkit-scrollbar {
  display: none;
}

.imageFileTagStyles {
  display: flex;
  flex-direction: column;
  width: 63px;
  height: 71px;
  margin-top: 12px;
  gap: 4px;
}
.imageStyles {
  width: 63px;
  height: 53px;
  border: 1px solid var(--bdo-semi-transparent-lighter);
}

.imageFileStyles {
  width: 63px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--bdo-semi-transparent-lighter);
}

.fileImageStyles {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageCloseStyles {
  position: absolute;
  top: 1px;
  right: 1px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  width: 7.14px;
  height: 7.14px;
}

.fileUploadingOverlayLoaderStyles {
  position: absolute;
  top: 11;
  left: 0;
  width: 63px;
  height: 52px;
  gap: var(--bdo-scale-sm);
  background: var(--bdo-shade-dark);
  border: 1px solid var(--bdo-semi-transparent-lighter);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
