.actionBarMainContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: var(--mono-lightest);
  padding: 0px var(--bdo-scale-sm) 0px 0px;
}
.actionBarButtonsWrapper {
  display: flex;
  align-items: center;
  gap: var(--bdo-scale-xxs);
}
.switchWrapper {
  display: flex;
  align-items: center;
}
[class*="ant-switch-checked"] {
  background-color: var(--bdo-secondary) !important;
}
[class*="ant-switch-handle"] {
  margin-top: 0.74px !important;
  height: var(--bdo-scale-sm) !important;
  width: var(--bdo-scale-sm) !important;
}

.activeTextStyles {
  margin: 0 0 0 10px;
}
