[class*="ant-drawer-content"] {
  background-color: white !important;
}
.ModelSelectionDrawer [class*="anticon-close"] {
  color: var(--bdo-charcoal);
}
.ModelSelectionDrawer [class*="ant-drawer-close"] {
  position: absolute;
  right: 0;
}
.ModelSelectionDrawer [class*="ant-drawer-title"] {
  font-size: var(--bdo-scale-lg) !important;
  font-weight: var(--bdo-text-bold) !important;
  color: var(--bdo-charcoal) !important;
}
.ModelSelectionContent {
  display: flex;
  flex-direction: column;
  gap: var(--bdo-scale-xs);
  margin-bottom: var(--bdo-scale-md) !important;
}

.ModelSelectionDrawer [class*="ant-drawer-header"] {
  padding: var(--bdo-scale-md) !important;
  border: none !important;
  padding-bottom: var(--bdo-scale-sm) !important;
}
.ModelSelectionDrawer [class*="ant-drawer-header-title"] {
  padding: 0 !important;
}

.ModelSelectionDrawer [class*="ant-drawer-body"] {
  padding: var(--bdo-scale-md) !important;
}
