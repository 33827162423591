.infoSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: var(--bdo-scale-md);
}

.infoText {
  color: var(--bdo-secondary);
  font-size: var(--bdo-scale-sm);
  margin: var(--bdo-scale-xxs) 0px;
}

.downloadButton {
  font-size: 12px;
}
.downloadConversationWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}
