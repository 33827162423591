.chatWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sourcesWrpper {
  margin-top: var(--bdo-scale-sm);
}

.chatItem {
  width: 100%;
  max-width: 800px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatarWrapper {
  background-color: white;
  border: 1px solid var(--bdo-charcoal-light);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
}
.copyButtonStyles {
  background-color: transparent !important;
  cursor: pointer;
  color: var(--sub-text);
  font-size: var(--bdo-scale-lg);
}
.copyButtonStyles:hover {
  background-color: transparent !important;
  cursor: pointer;
  color: var(--bdo-charcoal);
}
.stopIconStyles {
  margin-top: -2px !important;
}

.linkButton {
  color: var(--bdo-link) !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0;
  border-bottom: 1px solid transparent !important;
  height: auto !important;
  word-break: break-all;
}
.linkButton span {
  word-break: break-all !important;
  white-space: normal !important;
  text-align: justify;
}
.linkButton:hover {
  color: var(--bdo-link) !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  border-bottom: 1px solid var(--bdo-link) !important;
}

.iconButtonsContainerStyles {
  width: 35px;
  height: 28px;
  padding: var(--bdo-scale-2xs) var(--bdo-scale-xs);
  gap: var(--bdo-scale-xs);
}
.systemChangeNotificationWrapperStyles {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 5rem;
  border-bottom: 1px solid #e7e7e7;
}
.systemChangeNotificationStyles {
  display: flex;
  align-items: center;
  max-width: 800px;
  width: 100%;
}
.systemChangeNotificationHrStyles {
  border: 1px solid var(--bdo-semi-transparent-lighter);
  width: 100%;
}
.systemNotificationTextStyles {
  white-space: nowrap;
  margin: 0 var(--bdo-scale-md);
  color: var(--mono);
  font-style: italic;
  font-size: var(--bdo-scale-sm);
}

.systemMessage {
  padding: 4px 0px !important;
}
