.mainLayout {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: -webkit-fill-available;
}
.mainLayout [class*="ant-layout"] {
  overflow: hidden;
}
.layoutContent {
  flex: 1;
  display: flex;
  overflow: auto;
}
.loaderStyles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.spinnerStyles {
  [class*="anticon-loading"] {
    font-size: 60px !important;
  }
}
