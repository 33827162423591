@charset "utf-8";

html {
  font-size: 14px;
}
:root {
  --bdo-primary: #e81a3b;
  --bdo-primary-light: #f48d9d;
  --bdo-primary-dark: #ba152f;
  --bdo-secondary: #5b6e7f;
  --bdo-secondary-light: #adb7bf;
  --bdo-white: #fff;
  --bdo-link: #0062b8;
  --bdo-link-dark: #004e93;
  --sub-text: #858585;
  --sub-text-light: #adadad;
  --bdo-charcoal: #333333;
  --bdo-charcoal-light: #3333331f;
  --bdo-charcoal-lightest: #f2f2f2;
  --mono: #686868;
  --mono-lighter: #e7e7e7;
  --mono-lightest: #f2f2f2;
  --bdo-focus: #b3ddf2;
  --bdo-cobalt-lighter: #b3d0ea;
  --bdo-ocean: #02a5e2;
  --bdo-success: #198754;
  --bdo-error: #98002e;
  --bdo-error-lightest: #f5e6ea;
  --bdo-tint-lightest: #ffffffef;
  --bdo-warning-dark: #ab6100;
  --bdo-warning-lightest: #fbf2e6;

  /* Standard colours */
  --bdo-slate: #657c91;
  --bdo-gold: #df8639;
  --bdo-red: #ed1a3b;

  /* Standard colours - light */
  --bdo-charcoal-light: #cbcbcb;
  --bdo-gold-light: #eec29b;
  --bdo-red-light: #f9c5cd;

  /* Standard colours - dark */
  --bdo-ocean-dark: #004b68;

  /* Greyscale colour set */
  --bdo-colour-primary: var(--bdo-charcoal);

  /* Default theme accent colours */
  --bdo-colour-accent-primary: var(--bdo-red);
  --bdo-colour-accent-secondary: var(--bdo-slate);

  /* Functional colours */
  --bdo-colour-hyperlink: var(--bdo-ocean-dark);

  /* Background colours */
  --bdo-colour-background: #e5e5ea;

  /* Border colours */
  --bdo-semi-transparent-lightest: rgba(51, 51, 51, 0.06);
  --bdo-semi-transparent-lighter: rgba(51, 51, 51, 0.12);
  --bdo-semi-transparent-light: rgba(51, 51, 51, 0.5);

  /* Tints, shades & gradients */
  --bdo-shade: rgb(51 51 51 / 0.15);
  --bdo-shade-light: rgb(51 51 51 / 0.05);
  --bdo-shade-dark: rgb(51 51 51 / 0.25);
  --bdo-gradient-light: linear-gradient(
    35deg,
    var(--bdo-shade-light) 0%,
    transparent 100%
  );
  --bdo-shade-light-dark: #33333366;
  --bdo-drop-shadow: 0 3px 6px 0px rgb(51 51 51 / 5%);

  /* Typography */
  --bdo-scale-macro-1: 3.25rem; /* 45.5px */
  --bdo-scale-16xl: 7rem; /* 98px */
  --bdo-scale-13xl: 8.5rem; /* 119px */
  --bdo-scale-6xl: 3.313rem; /* 53px */
  --bdo-scale-5xl: 3rem; /* 42px */
  --bdo-scale-2xs: 0.25rem; /* 3.5px */
  --bdo-scale-xxxl: 2.562rem; /* 35.868px */
  --bdo-scale-xxl: 2rem; /* 28px */
  --bdo-scale-xl: 1.625rem; /* 22.75px */
  --bdo-scale-lg: 1.25rem; /* 17.5px */
  --bdo-scale-md: 1rem; /* 14px */
  --bdo-scale-sm: 0.75rem; /* 10.5px */
  --bdo-scale-xs: 0.812rem; /* 11.368px */
  --bdo-scale-xxs: 0.5rem; /* 7px */
  --bdo-scale-xxxs: 0.25rem; /* 3.5px */
  --bdo-scale-4xl: 2.5rem; /* 35px */
  --bdo-scale-16xl: 8.5rem; /* 119px */

  --bdo-xxs: 0.688rem;
  --bdo-xs: 0.812rem;
  --bdo-sm: 0.875rem;
  --bdo-md: 1rem;
  --bdo-xl: 1.25rem;
  --bdo-xxl: 1.428rem;
  --bdo-xxxl: 1.625rem;

  /* Font Weight */
  --bdo-text-light: 300;
  --bdo-text-base: 400;
  --bdo-text-lightBold: 500;
  --bdo-text-medium: 600;
  --bdo-text-bold: 700;
  --bdo-text-extrabold: 900;

  /* BDO custom fonts */
  --bdo-font-primary: "Proxima Nova Rg", sans-serif;
}
