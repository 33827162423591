@keyframes alternatingPulse {
  0%,
  100% {
    fill: #d67900;
  }
  25%,
  75% {
    fill: #ebbc80;
  }
  50% {
    fill: #f3d7b3;
  }
}

.pulse1,
.pulse2 {
  animation: alternatingPulse 4s infinite;
}

.pulse1 {
  animation-delay: 0s;
}

.pulse2 {
  animation-delay: 2s;
}
