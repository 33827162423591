.bdoFooter {
  width: 100%;
  padding: var(--bdo-scale-xs) var(--bdo-scale-lg);
  background-color: var(--bdo-charcoal);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: static;
}
.textWrapper {
  display: flex;
  color: var(--bdo-white);
  font-size: var(--bdo-scale-sm) !important;
  font-style: normal;
  font-weight: var(--bdo-text-base);
  max-width: 800px;
}
.linkStyles {
  color: var(--bdo-cobalt-lighter);
}
.linkStyles:hover {
  color: var(--bdo-cobalt-lighter);
}
.footerText {
  margin-bottom: 0;
  margin-top: 0;
  max-width: 56em;
}
.textInfoIcon {
  font-size: var(--bdo-scale-lg);
  align-self: flex-start;
  margin-right: var(--bdo-scale-xxs);
}

.personasLogo {
  height: var(--bdo-scale-xxl);
  margin-right: var(--bdo-scale-xxl);
}
.bdoLogo {
  height: var(--bdo-scale-xl);
}
.footerLogoWrapper {
  margin-left: var(--bdo-scale-lg);
}
